.main-banner {
    position: relative;
    z-index: 99;
    min-height: 100vh;
    padding-bottom: 200px;

    .banner-bg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 137px;
        z-index: -1;
    }

    .banner-content {
        max-width: 546px;
        width: 100%;
        margin: 0 auto;
        padding-top: 147px;

        .timermain {
            border-radius: 12px;
            border: 1px solid #E6E6E6;
            background: #FFF;
            box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
            display: flex;
            width: 100%;
            padding: 20px 30px;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 25px;

            .timerleft {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 30px;

                .mainiconimg {
                    width: 55px;
                    height: 55px;
                    border-radius: 120px;
                    object-fit: cover;
                    object-position: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .iconimginner {
                        width: 100%;
                        height: 100%;
                        border-radius: 120px;
                        object-fit: cover;
                        object-position: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                .timerhead {
                    color: #000;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 30px;
                }

                .timerpara {
                    color: #B6B6B6;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 30px;
                }
            }

            .timerended {
                color: #000;
                text-align: center;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 30px;
            }

            .timerspans {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;

                .maintime {
                    width: 54.5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: 5px;

                    .innertimer {
                        border-radius: 12px;
                        border: 1px solid #E6E6E6;
                        background: #FFF;
                        box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
                        display: flex;
                        padding: 11px 5px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        width: 40px;
                        height: 40px;

                        .innertimertext {
                            color: #000;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 110%;
                        }
                    }

                    .innertimepara {
                        color: #000;
                        text-align: center;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 110%;
                    }
                }
            }
        }

        .upper-content {
            border-radius: 12px;
            border: 1px solid #E6E6E6;
            background: #FFF;
            box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
            display: flex;
            justify-content: space-between;
            align-items: center;
            ;
            padding: 20px 30px;
            margin-bottom: 20px;

            .left {
                display: flex;
                align-items: center;
                gap: 11px;

                .text {
                    h6 {
                        color: #000;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 100%;
                        letter-spacing: 0.1px;
                        margin-bottom: 5px;
                    }

                    p {
                        color: #717171;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 100%;
                        letter-spacing: 0.1px;
                    }
                }
            }

            .right {
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;

                h6 {
                    color: #000;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 100%;
                    letter-spacing: 0.1px;
                    margin-bottom: 8px;
                    text-align: end;
                }

                p {
                    color: #28E664;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 100%;
                    letter-spacing: 0.1px;
                    text-align: end;
                }

                .red {
                    color: #f41e5e;
                }
            }
        }

        .bottom-content {
            border-radius: 12px;
            border: 1px solid #E6E6E6;
            background: #FFF;
            box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
            padding: 20px;

            .main-head {
                color: #000;
                text-align: start;
                font-size: 24px;
                font-weight: 700;
                line-height: 30px;
                margin-bottom: 15px;
            }

            .parent-box {
                position: relative;

                .swap-arrow {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .inner-box {
                border-radius: 12px;
                border: 1px solid #E6E6E6;
                background: #FFF;
                box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
                padding: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;

                .leftiii {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    max-width: 102px;
                    width: 100%;
                    border-radius: 12px;
                    border: 1px solid #E6E6E6;
                    background: #FFF;
                    box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
                    padding: 10px;

                    h6 {
                        color: #000;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 100%;
                        letter-spacing: 0.1px;
                    }
                }

                .left {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    max-width: 110px;
                    width: 100%;

                    .dropdown {
                        width: 100%;

                        .dropdown-toggle {
                            border-radius: 12px;
                            border: 1px solid #E6E6E6;
                            background: #FFF;
                            box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
                            padding: 10px;
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            color: #000 !important;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 100%;
                            letter-spacing: 0.1px;

                            // &::after {
                            //     display: none;
                            // }
                        }

                        .dropdown-menu {
                            border-radius: 12px;
                            border: 1px solid #E6E6E6;
                            background: #FFF;
                            padding: 0px;
                            min-width: 166px;
                            width: 100%;
                            overflow: hidden;

                            a {
                                color: #000;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 100%;
                                letter-spacing: 0.1px;
                                padding: 14px 15px;
                                border-bottom: 1px solid #F3F3F3;
                                background: #FFF;
                                display: flex;
                                justify-content: flex-start;
                                align-items: center;

                                &:hover {
                                    background: rgba(0, 0, 0, 0.05) !important;
                                }

                                img {
                                    width: 18px;
                                    height: 18px;
                                    margin-right: 10px;

                                }
                            }
                        }
                    }

                    .text {
                        h6 {
                            color: #000;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 100%;
                            letter-spacing: 0.1px;
                        }
                    }
                }

                .right {
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-end;
                    flex-direction: column;

                    h6 {
                        color: #000;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 100%;
                        text-align: right;
                    }

                    // p{
                    //     color: rgba(255, 255, 255, 0.20);
                    //     font-size: 28px;
                    //     font-weight: 700;
                    //     line-height: 120%;
                    //     text-align: end;
                    // }
                    input {
                        color: #000;
                        font-size: 28px;
                        font-style: normal;
                        font-weight: 700;
                        text-align: end;
                        background-color: transparent;
                        border: none;
                        max-width: 200px;
                        width: 100%;


                        &::placeholder {
                            color: #000;
                            font-size: 28px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                            text-align: end;
                        }
                    }
                }
            }

        }

        .bottom-text {
            margin-top: 20px;

            .inner-text {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;

                p {
                    color: #000;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                h6 {
                    color: #000;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }

            .inner-text-bold {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;

                h6 {
                    color: #000;
                    text-align: right;
                    font-size: 25px;
                    font-weight: 700;
                }
            }
        }

        .btn-buy {
            border-radius: 15px;
            background: #F8F8F8;
            box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.02) inset;
            display: flex;
            padding: 18px 25px;
            justify-content: center;
            align-items: center;
            color: #D9D9D9;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            text-transform: uppercase;
            width: 100%;
            border: none;
        }
    }

    .twice-content {
        display: grid;
        // grid-template-columns: 1fr 1fr;
        // gap: 20px;
        margin-bottom: 20px;

        .upper-content {
            margin-bottom: 0 !important;
            padding: 20px !important;
        }

        .upper-content .right h6 {
            font-size: 18px !important;
        }

        .availablemain {
            max-width: 260px;
            width: 100%;
            padding: 20px;
            border-radius: 12px;
            border: 1px solid #E6E6E6;
            background: #FFF;
            box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;

            .availablehead {
                color: var(--Black, #000);
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
                letter-spacing: 0.1px;

                display: flex;
                align-items: center;
                gap: 6px;
            }

            .availablepara {
                color: #B6B6B6;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                letter-spacing: 0.1px;
                margin-top: 8px;
                margin-bottom: 11px;

                .greynumber {
                    color: #717171;
                }
            }

            .progress {
                background: rgba(55, 55, 55, 0.20);
                height: 11px;
                border-radius: 15px;

                .progress-bar {
                    background-color: #53FFEA;
                    border-radius: 15px;
                }
            }
        }


    }

}

.newClasss {
    border-radius: 15px;
    background: #4CEAD7;
    box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.20) inset;
    display: flex;
    padding: 18px 25px;
    justify-content: center;
    align-items: center;
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    border: none;
    text-transform: uppercase;
    width: 100%;
}

.suxvsvsyucsusc {
    width: 30px;
    height: 30px;
}

.custom-tabs {
    .nav-tabs {
        margin-bottom: 20px;

        border-radius: 12px;
        border: 1px solid #E6E6E6;
        justify-content: space-between;

        .nav-item {
            flex: 1;

            .nav-link {
                width: 100%;
                padding: 20px 10px;
                border: none;
                background-color: transparent;
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 110%;
                border-radius: 0;
            }
        }

        .nav-item.show .nav-link,
        .nav-link.active {
            color: #fff;
            border-radius: 8px;
            background: var(--Black, #000);
            box-shadow: 0px -7px 0px 0px rgba(255, 255, 255, 0.20) inset;
        }
    }
}

.discount-offer {
    border-radius: 12px;
    background: var(--Black, #000);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin: 10px 0;
    position: relative;
    z-index: 9;
    overflow: hidden;
    .discont-bg{
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        pointer-events: none;
        width: 100%;
        height: 100%;
    }
    .left-sidee{
        .upper-text{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-bottom: 10px;
           
            h6{
                color: #FFC700;
                font-size: 22px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%; 
                letter-spacing: -0.22px;
                text-transform: uppercase;
            }
        }
        p{
            color: #BED7D3;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            letter-spacing: 0.28px;
            max-width: 336px;
            width: 100%;
        }
    }
    .right-sidee{
        .btn-discount{
            border-radius: 15px;
            background: #53FFEA;
            box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.20) inset;
            padding: 16px 17px;
            color: var(--Black, #000);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%; 
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .see-plan{
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            .btn-seeplan{
                border-radius: 15px;
                border: 1px solid #292929;
                background: var(--Black, #000);
                box-shadow: 0px -5px 0px 0px rgba(255, 255, 255, 0.16) inset;
                padding: 16px 17px;
                color: #FFF;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                text-transform: uppercase;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            p{
                color: #FFF;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%; 
                letter-spacing: 0.24px;
                margin-top: 5px;
            }
        }
    }

}

.inner-text-bolddddd{
    .twice-text{
        h6{
            color: var(--Black, #000);
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
        p{
            color: #717171;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            margin-top: 2px;
        }
    }
}
.border-set-custom{
    background: #E6E6E6;
    width: 100%;
    display: flex;
    height: 1px;
    margin: 20px 0;
}

@media (max-width:600px) {
    .main-banner .banner-content .timermain .timerleft .timerhead {
        font-size: 16px;
    }

    .main-banner .banner-content .timermain .timerleft .timerpara {
        font-size: 12px;
    }

    .main-banner .banner-content .timermain .timerspans .maintime .innertimer .innertimertext {
        font-size: 12px;
    }

    .main-banner .banner-content .timermain .timerspans .maintime .innertimer {
        padding: 0;
        width: 30px;
        height: 28px;
    }

    .main-banner .banner-content .timermain .timerspans .maintime .innertimepara {
        font-size: 7px;
    }

    .main-banner .banner-content .timermain .timerspans .maintime {
        width: 31px;
    }

    .main-banner .banner-content .timermain .timerspans {
        gap: 7px;
    }

    section.main-banner {
        padding-bottom: 200px;
    }

    .main-banner .banner-content .upper-content .right h6 {
        font-size: 14px;
    }

    .main-banner .twice-content {
        grid-template-columns: auto
    }

    .main-banner .twice-content .availablemain {
        max-width: 100%;
    }
    .main-banner .banner-content .bottom-text .inner-text-bold h6{
        text-align: unset !important;
        font-size: 18px;
    }
    .main-banner .banner-content .timermain{
        padding: 20px;
    }
    .main-banner .banner-content .timermain .timerleft .timerpara, .main-banner .banner-content .timermain .timerspans .maintime .innertimer .innertimertext{
        line-height: 15px;
    }
    .main-banner .banner-content .timermain .timerleft{
        gap: 10px;
    }
}

@media (max-width:600px) {
    .main-banner .material-textfield label {
        padding: 0 0rem 0 0.3rem !important;
    }
    .discount-offer{
        flex-direction: column;
        gap: 15px;
    }
    .discount-offer .right-sidee {
        width: 100%;
    }
    .discount-offer .right-sidee .see-plan p{
        text-align: center;
    }
}

.right-text-content {
    display: flex;
    align-items: center;
    gap: 10px;

    .inner-left {
        h5 {
            background: black;
            padding: 4px 6px;
            color:white;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: 0.1px;
            text-transform: uppercase;
            margin-bottom: 6px;
        }

        .special-para {
            color: #B6B6B6 !important;
            text-align: center !important;
            font-size: 14px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 100% !important;
            letter-spacing: 0.1px !important;
        }
    }

    .inner-right {
        p {
            color: #B6B6B6 !important;
            font-size: 16px !important;
            font-style: normal !important;
            font-weight: 400 !important;
            line-height: 100% !important;
            letter-spacing: 0.1px !important;
            margin-top: 8px !important;
            text-decoration: line-through;
        }
    }
}


.buyjustdop {
    .dropdown .dropdown-menu a img {
        width: 18px !important;
        height: 18px !important;
        object-fit: cover;
    }
}

.error-mesg-show {
    color: #FF4A4A;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-align: center;
    margin-top: 8px;
}

.error-color {
    color: #FF4A4A !important;
}


.tooltip-inner {
    border-radius: 12px !important;
    border: 1px solid #242424 !important;
    background: var(--Black, #000) !important;
    box-shadow: 0px 14px 34px 0px rgba(0, 0, 0, 0.10) !important;
    padding: 17px !important;
    max-width: 303px !important;

    h5 {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        margin-bottom: 8px;
        text-align: start !important;
    }

    p {
        color: #CECECE;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: start !important;
    }
}



.Apppppppp {
    text-align: center;

    input[type="range"] {
        width: 100%;
        margin: 20px 0;
    }

    .gauge-container {
        width: 100%;
        margin: 20px 0;
        margin-bottom: 0;
    }

    .gauge {
        position: relative;
        width: 100%;
        height: 10px;
        background: linear-gradient(to right, red, orange, yellow, green);
        border-radius: 10px;
    }

    .gauge-indicator {
        position: absolute;
        top: 0px;
        width: 4px;
        height: 10px;
        background-color: #000;
        transition: left 0.3s ease;
    }

    .gauge-labels {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
    }

    .gauge-labels span {
        color: gray;
        font-size: 14px;
    }
}

@media (max-width:390px){
    .right-text-content .inner-left h5{
        white-space: nowrap;
    }
    .main-banner .banner-content .upper-content .right .inner-right{
        max-width: 80px;
    }
    .right-text-content{
        align-items: flex-start;
    }
}