.main-staking {
    padding-bottom: 100px;
    padding-top: 72px;

    .upperbanner {
        background-image: url('../../../src/assets/backgroundbanner.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 413px;
        padding-top: 60px;

        .staketoppara {
            color: #53FFEA;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.36px;
            text-transform: uppercase;
            margin-bottom: 20px;
        }

        .stakemainhead {
            color: #FFF;
            font-size: 46px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            max-width: 696px;
            width: 100%;
            letter-spacing: 0.92px;
        }

        p {
            color: #FFF;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: 0.44px;
            max-width: 696px;
            width: 100%;
            margin-top: 20px;
        }
    }

    .lowerbanner {
        margin-top: -130px;

        .lowerinnerbanner {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 12px;

            .lowerleft {
                max-width: 459px;
                width: 100%;
                padding: 25px;
                border-radius: 12px;
                background: var(--Black, #000);
                position: relative;
                overflow: hidden;

                .lowerleftshade {
                    width: 310px;
                    height: 215px;
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 0%);
                    bottom: -258px;
                    border-radius: 310px;
                    background: #4CEAD7;
                    filter: blur(105px);
                }

                .totalpara {
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    letter-spacing: 0.28px;
                    text-transform: uppercase;
                    margin-bottom: 10px;
                }

                .totalgemsmain {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    width: 100%;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
                    padding-bottom: 25px;
                    margin-bottom: 25px;

                    .gemslogoimg {
                        width: 34px;
                        height: 34px;
                    }

                    .totalgemspara {
                        color: #4CEAD7;
                        font-size: 28px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%;
                        text-transform: uppercase;
                    }
                }

                .stakebalance {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 10px;

                    .stakepara {
                        color: #FFF;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%;
                        letter-spacing: 0.24px;
                        text-transform: uppercase;
                    }

                    .balancepara {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        gap: 5px;
                        color: #FFF;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 100%;
                    }
                }

                .amountinput {
                    border-radius: 10px;
                    border: 1.5px solid rgba(255, 255, 255, 0.15);
                    background: rgba(255, 255, 255, 0.02);
                    padding: 20px 16px;
                    outline: none;
                    width: 100%;
                    color: #FFF;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                    margin-bottom: 25px;

                    &::placeholder {
                        color: #8D8D8D;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 100%;
                    }
                }

                .stakingperiodmain {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 25px;

                    .stakingperiodpara {
                        color: #FFF;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 100%;
                    }

                    .stakingyearpara {
                        color: #FFF;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 120%;
                        letter-spacing: 0.24px;
                    }
                }

                .approvebtn {
                    display: flex;
                    padding: 18px 25px;
                    justify-content: center;
                    align-items: center;
                    color: var(--Black, #000);
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    text-transform: uppercase;
                    border-radius: 15px;
                    background: #53FFEA;
                    box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.20) inset;
                    outline: none;
                    border: none;
                    width: 100%;
                    z-index: 9999;
                }
            }

            .lowerright {
                border-radius: 12px;
                border: 1px solid #E6E6E6;
                background: #FFF;
                width: 100%;

                .lowerrightpara {
                    color: var(--Black, #000);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0.36px;
                    text-transform: uppercase;
                    padding: 18px 20px;
                    border-bottom: 1px solid #E6E6E6;
                    background: #FFF;
                    border-top-left-radius: 12px;
                    border-top-right-radius: 12px;
                }
            }
        }
    }

    .newboxes-twice {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        margin-top: 50px;

        .single-box {
            border-radius: 12px;
            border: 1px solid #555;
            background: var(--Black, #000);
            padding: 25px;
            position: relative;
            z-index: 9;
            height: 411px;

            &.activee {
                border-radius: 12px;
                border: 1px solid #53FFEA;
                background: var(--Black, #000);
                overflow: hidden;

                .gradient-activee {
                    border-radius: 505.09px;
                    background: #4CEAD7;
                    filter: blur(105px);
                    width: 505.09px;
                    height: 215px;
                    display: flex;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -100%);
                    z-index: -1;
                    pointer-events: none;
                }
            }

            .upper-content {
                padding-bottom: 25px;
                border-bottom: 1px solid rgba(255, 255, 255, 0.15);

                h6 {
                    color: #FFF;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 120%;
                    letter-spacing: 0.44px;
                    text-transform: uppercase;
                }

                h4 {
                    margin: 10px 0;
                    color: #666;
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    letter-spacing: 0.8px;
                    text-transform: uppercase;

                    span {
                        color: #FFF;
                        font-size: 40px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                        letter-spacing: 0.8px;
                        text-transform: uppercase;
                    }
                }

                p {
                    color: #FFF;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    letter-spacing: 0.36px;
                }
            }

            .bottom-content {
                padding: 7px 20px;
                border-radius: 12px;
                border: 1px solid #212121;
                background: rgba(44, 44, 44, 0.20);
                margin-top: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                flex-direction: column;

                .inner-single {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    padding: 13px 0;
                    border-bottom: 1px solid #212121;

                    .text {
                        display: flex;
                        gap: 10px;
                        align-items: center;

                        p {
                            color: #FFF;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 120%;
                            letter-spacing: -0.14px;

                            &.cutting {
                                text-decoration: line-through !important;
                            }
                        }
                    }
                }
            }

            .twice-btns {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 39px;

                a {
                    padding: 18px 25px;
                    color: var(--Black, #000);
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    text-transform: uppercase;
                    width: 49%;
                    width: 100%;
                    display: block;
                    margin: 0 auto;
                }

                .withdraw-gems {
                    border-radius: 15px;
                    border: 1px solid #313131;
                    background: rgba(0, 0, 0, 0.50);
                    color: #fff;
                }

                .upgrade-gems {
                    border-radius: 15px;
                    background: #53FFEA;
                    box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.20) inset;
                    color: #000;
                }
            }
        }

        .bottom-para {
            color: var(--Black, #000);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: -0.14px;
            max-width: 441px;
            width: 100%;
            margin: 0 auto;
            margin-top: 13px;
        }

        .btn-plan {
            border-radius: 15px;
            border: 1px solid #313131;
            background: rgba(0, 0, 0, 0.50);
            padding: 18px 25px;
            color: #FFF;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 39px;
            pointer-events: none;

            &.activee {
                border-radius: 15px;
                background: #53FFEA;
                box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.20) inset;
                color: var(--Black, #000);
                pointer-events: visible;


            }

            h6 {
                color: #FFF;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                text-transform: uppercase;
                margin-bottom: 3px;
            }

            p {
                color: #FFF;
                text-align: center;
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                letter-spacing: 0.26px;
                text-transform: uppercase;
            }
        }
    }
}

.stakingmodal {
    .modal-dialog {
        max-width: 554px;

        .modal-content {
            border-radius: 15px;
            background: #FFF;
            padding: 25px;

            .modal-header {
                padding: 0px;
                border: none;
                margin-bottom: 20px;

                .modal-title {
                    color: var(--Black, #000);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                }

                .btn-close {
                    background: url('../../../src/assets/btnclose.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    opacity: unset !important;
                    border: none;
                    outline: none;
                    box-shadow: none;
                    padding: 0;
                    margin: 0;
                    width: 30px;
                    height: 30px;
                }
            }

            .modal-body {
                padding: 0px;

                .addedmodal {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    .successhead {
                        color: var(--Black, #000);
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 120%;
                        margin-top: 20px;
                        letter-spacing: -0.2px;
                        text-transform: uppercase;
                    }

                    .succesaspara {
                        color: #2F2F2F;
                        text-align: center;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%;
                        margin-bottom: 20px;
                    }
                }

                .stakingmodal {
                    .stakingamoutmain {
                        width: 100%;
                        display: flex;
                        padding: 26.5px 37px;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        border-radius: 12px;
                        background: var(--Black, #000);
                        position: relative;
                        overflow: hidden;
                        margin-bottom: 15px;

                        .whiteshade {
                            border-radius: 430px;
                            background: #4CEAD7;
                            filter: blur(105px);
                            width: 430px;
                            height: 215px;
                            position: absolute;
                            bottom: -190px;
                            left: 50%;
                            transform: translate(-50%, 50%);
                        }

                        .stakingamountpara {
                            color: #FFF;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 120%;
                            margin-bottom: 5px;
                            letter-spacing: 0.24px;
                            text-transform: uppercase;
                            text-align: center;
                            z-index: 9;
                            position: relative;
                        }

                        .stakingamounthead {
                            color: #53FFEA;
                            font-size: 28px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 120%;
                            letter-spacing: -0.28px;
                            text-transform: uppercase;
                            text-align: center;
                            width: 100%;
                            z-index: 9;
                            position: relative;
                        }
                    }

                    .stakingdetails {
                        border-radius: 12px;
                        border: 1px solid #E6E6E6;
                        background: #FFF;
                        margin-bottom: 20px;

                        .stakingdetailinner {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 14px 18px;
                            border-bottom: 1px solid #F2F2F2;

                            &:last-child {
                                border-bottom: none;
                            }

                            .stakingdetailpara {
                                color: #777;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                            }

                            .stakingdetailhead {
                                color: var(--Black, #000);
                                text-align: center;
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 500;
                                line-height: normal;
                            }
                        }
                    }

                    .stakingbtns {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 10px;

                        .cancelbtn {
                            border-radius: 15px;
                            border: 1px solid #E6E6E6;
                            background: #FFF;
                            box-shadow: 0px -7px 0px 0px rgba(0, 0, 0, 0.04) inset;
                            display: flex;
                            padding: 18px 25px;
                            justify-content: center;
                            align-items: center;
                            color: var(--Black, #000);
                            text-align: center;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 100%;
                            width: 100%;
                            text-transform: uppercase;
                        }

                        .confirmbtn {
                            display: flex;
                            padding: 18px 25px;
                            justify-content: center;
                            align-items: center;
                            border-radius: 15px;
                            background: #53FFEA;
                            box-shadow: 0px -5px 0px 0px rgba(0, 0, 0, 0.20) inset;
                            border: none;
                            color: var(--Black, #000);
                            text-align: center;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 100%;
                            text-transform: uppercase;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width:992px) {
    .main-staking .lowerbanner .lowerinnerbanner {
        flex-direction: column;
    }

    .main-staking .lowerbanner .lowerinnerbanner .lowerleft {
        max-width: 100%;
    }

    .main-staking .upperbanner .stakemainhead {
        font-size: 30px;
    }

    .main-staking .upperbanner .staketoppara {
        font-size: 16px;
    }

    .stakingmodal .modal-dialog .modal-content .modal-body .stakingmodal .stakingbtns {
        flex-direction: column;
    }

    .main-staking .newboxes-twice {
        grid-template-columns: auto;
    }

    .main-staking .newboxes-twice .single-box {
        height: auto;
    }

    .main-staking .lowerbanner {
        margin-top: -90px;
    }

    .main-staking .newboxes-twice .single-box .twice-btns {
        flex-direction: column;
        gap: 15px;
    }

    .stakingmodal .modal-dialog .modal-content .modal-body .stakingmodal .directionchange {
        flex-direction: column-reverse;
    }

    .premiumstake-modal .inner-content .newpara {
        font-size: 21px !important;

        .newboldtext {
            font-weight: 700;
        }
    }
    .fornewbackdrop{
        .newheightcontent{
            .modalbgdnoneformbl{
                display: none !important;
            }
            .newmodalbgimg{
                display: block !important;
                border-radius: 20px;
            }
        }
    }
}

.premiumstake-modal {
    .newheightcontent {
        height: 291px;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .inner-content {
        position: relative;
        z-index: 9;
        overflow: hidden;
        padding-top: 43px;
        padding-bottom: 68px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;

        .newpara {
            color: #FFF;
            text-align: center;
            font-size: 30px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            letter-spacing: 0.6px;
            text-transform: unset;
            margin-top: 10px;

            .newboldtext {
                font-weight: 700;
            }
        }

        .modalbg-stake {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            pointer-events: none;
            width: 100%;
            height: 100%;
        }

        h5 {
            color: #FFF;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: -0.2px;
            text-transform: uppercase;
            margin-top: 20px;
            margin-bottom: 6px;
        }

        p {
            color: #FFF;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
        }

    }
}

.cdhbdybydbcy {
    color: #FF4A4A !important;
    text-align: center !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 120% !important;
    letter-spacing: 0.24px !important;
    text-transform: uppercase !important;
    border-radius: 6px;
    background: rgba(255, 74, 74, 0.16);
    padding: 6px 10px;
}

.fornewbackdrop {
    background: rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(4px);
}

.fornewbackdrop .modal-dialog .modal-content {
    padding: 20px;
}